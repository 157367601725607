import { useToast } from '@latitude/toast';
import classNames from 'classnames';
import { LOGIN_PAGE_DISCLOSURE, LOGIN_PAGE_DISCLOSURE_PORTO } from 'constants/compliance';
import { Loader } from '@anchorage/common/dist/components';
import { AnchorageDigitalLogoIcon, PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import DisclosureText from '@anchorage/frontoffice/components/DisclosureText';
import { isProduction } from '@anchorage/frontoffice/constants/app';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';
import css from './styles.module.scss';
import QRCode from '../QRCode';
const SESSION_ID_INPUT = 'session-id';
type Props = {
  isServerError: boolean;
  isLoadingUserData: boolean;
  onClickRefresh: () => void;
  qrCodeContents: {
    [key: string]: string;
  };
  showTimeout: boolean;
};
function Login(props: Props) {
  const isPorto = useIsPortoApp();
  const {
    toast
  } = useToast();
  function renderQRCodeArea() {
    const {
      isLoadingUserData,
      isServerError,
      onClickRefresh,
      qrCodeContents,
      showTimeout
    } = props;
    const currentQRCode = <div className={css.qrWrapper}>
        {isServerError ? <div className={css.serverError} data-testid="login-server-error">
            <div className={css.errTitle}>
              Sorry, we couldn’t create your QR Code
            </div>
            <div>
              Something isn’t working on our end, but we’re working to fix it.
              Refreshing this page may help.
            </div>
          </div> : <div>
            <QRCode qrCodeContents={qrCodeContents} size={240} fgColor={'#000000'} />
          </div>}

        <div className={css.qrFrame} />
        <div className={css.qrFrame} />
        <div className={css.qrFrame} />
        <div className={css.qrFrame} />
      </div>;
    if (showTimeout) {
      return <div onClick={onClickRefresh} role="button" aria-label="refresh qr code" className={css.refresh} data-testid="login-refresh">
          {currentQRCode}
        </div>;
    }
    const qrCodeWithLoader = isLoadingUserData ? <div data-testid="loading-user-data" className={css.loadingDataWrapper}>
        {currentQRCode}
        <Loader className={css.loader} />
      </div> : currentQRCode;
    if (!isProduction) {
      // Click on the QR code to copy the session id. For dev use only.
      const inputVal = qrCodeContents && qrCodeContents.SessionID || '';
      const onClick = () => {
        const hiddenInput = document.getElementById(SESSION_ID_INPUT);
        if (hiddenInput && hiddenInput instanceof HTMLInputElement) {
          // Select the text with the session id
          hiddenInput.select();
          // Copy to clipboard
          const wasCopied = document.execCommand('copy');
          if (wasCopied) {
            toast({
              variant: 'success',
              title: 'Copied Session ID'
            });
          }
        }
      };
      return <div onClick={onClick} className={css.inputWrapper}>
          <input id={SESSION_ID_INPUT} value={inputVal} readOnly className={css.hidden} />
          {qrCodeWithLoader}
        </div>;
    }
    return qrCodeWithLoader;
  }
  const instructionItemClass = classNames({
    [css.instructionItem!]: true,
    [css.instructionItemPorto!]: isPorto
  });
  return <div className={css.page} data-sentry-component="Login" data-sentry-source-file="index.tsx">
      <div className={css.logoWrapper}>
        {isPorto ? <PortoLogoIcon data-testid="porto-logo" className={css.logo} /> : <AnchorageDigitalLogoIcon width={143} height={44} data-testid="anchorage-logo" className={css.logo} />}
      </div>
      <div className={css.contentsWrapper}>
        <div className={css.contentsContainer}>
          <div className={css.innerContainer}>
            <div>
              <div className={css.instructions}>
                <div className={css.title}>
                  Log in to your web
                  <br />
                  dashboard in a few steps
                </div>
                <ol className={css.instructionsList}>
                  <li className={instructionItemClass}>Open the app</li>
                  <li className={instructionItemClass}>Go to settings</li>
                  <li className={instructionItemClass}>
                    Tap web dashboard login
                  </li>
                  <li className={instructionItemClass}>
                    Scan the QR code on this page
                  </li>
                </ol>
              </div>
            </div>
            <div className={css.qrSection}>{renderQRCodeArea()}</div>
          </div>
          <DisclosureText className={css.disclosure} testId="login-page-disclosure" data-sentry-element="DisclosureText" data-sentry-source-file="index.tsx">
            {isPorto ? LOGIN_PAGE_DISCLOSURE_PORTO : LOGIN_PAGE_DISCLOSURE}
          </DisclosureText>
        </div>
      </div>
    </div>;
}
export default Login;