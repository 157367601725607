import { QRCodeSVG } from 'qrcode.react';
import LoadingDots from 'components/shared/LoadingDots';
import css from './styles.module.scss';
import { QR_CODE_SIZE } from '../constants';
type Props = {
  qrCodeContents?: {
    [key: string]: string;
  };
  size?: number;
  fgColor?: string;
};
const QRCode = ({
  qrCodeContents,
  size = QR_CODE_SIZE,
  fgColor = '#121e34'
}: Props) => {
  if (!qrCodeContents) {
    return <div data-testid="qr-code-loading" className={css.loading}>
        <LoadingDots />
      </div>;
  }

  // TODO Get the FG color from an environment variable
  return <QRCodeSVG className={css.qrCode} bgColor="white" fgColor={fgColor} size={size} value={JSON.stringify(qrCodeContents)} data-sentry-element="QRCodeSVG" data-sentry-component="QRCode" data-sentry-source-file="index.tsx" />;
};
export default QRCode;