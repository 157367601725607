import { formatCurrentDate } from '@anchorage/common/dist/utils/dates';

const CURRENT_YEAR = formatCurrentDate('year');

/* This is where all of the disclosure lives for the site. Change the text below to update the disclosure on the site */

export const COPYRIGHT = `©${CURRENT_YEAR} Anchorage Digital`;

const ANCHORAGE_TRUST_COMPANY_SERVICES = `Anchorage Digital is not engaged in the business of the offer, sale or trading of securities.`;
const ANCHORAGE_DISCLAIMERS = `Anchorage Digital does not provide legal, tax, or investment advice. Holdings of cryptocurrencies and other digital assets are speculative and involve a substantial degree of risk, including the risk of complete loss. There can be no assurance that any cryptocurrency, token, coin, or other crypto asset will be viable, liquid, or solvent. No Anchorage Digital communication is intended to imply that any digital asset services are low-risk or risk-free.`;
const ANCHORAGE_GUARANTEE_DISCLAIMER = `Digital assets held in custody are not guaranteed by Anchorage Digital and are not subject to the insurance protections of the Federal Deposit Insurance Corporation ("FDIC") or the Securities Investor Protection Corporation ("SIPC"), or the Singapore Deposit Insurance Corporation ("SDIC"). Anchorage Digital Singapore is not a member of the Singapore Deposit Insurance ("DI") Scheme and assets are not subject to the protections enjoyed by depositors with DI Scheme member institutions.`;

// Shared text between multiple disclosures
const ANCHORAGE_SERVICES_DISCLOSURE = `${ANCHORAGE_TRUST_COMPANY_SERVICES} ${ANCHORAGE_DISCLAIMERS}`;

const PRICE_PROVIDER = `Cryptocurrency price data is provided by CryptoCompare. Learn more at <a href="https://www.cryptocompare.com" target="_blank" rel="noopener noreferrer">www.cryptocompare.com.</a>`;

//------------------------------------------------------------------------------
// Login Page
//------------------------------------------------------------------------------
export const LOGIN_PAGE_DISCLOSURE = `By logging in, you are leaving the public Anchorage Digital website. Use of the web dashboard section of the Anchorage Digital website is governed by your entity’s master services agreement and any additional terms to which you or your entity have agreed.

${ANCHORAGE_SERVICES_DISCLOSURE}

${ANCHORAGE_GUARANTEE_DISCLAIMER}

${COPYRIGHT}`;

export const LOGIN_PAGE_DISCLOSURE_PORTO = `Products and software are provided by Anchorage Innovations, LLC (“Anchorage Innovations”). Anchorage Innovations is not a custodian, is not registered with the SEC or any other government authority as a broker or dealer and is not authorized to engage in the business of the offer, sale or trading of securities. Anchorage Innovations does not provide legal, tax, or investment advice.

Holdings of cryptocurrencies and other crypto assets are speculative and involve a substantial degree of risk, including the risk of complete loss of monetary value. Anchorage Innovations does not own or control, and is not responsible for any blockchain protocols, and you are solely responsible for any transactions that you engage in with blockchain protocols, including but not limited to staking, governance, or any other on-chain activities.

©${CURRENT_YEAR} Anchorage Innovations. All rights reserved. Refer to our Terms of Use and Privacy Policy for more information.`;

//------------------------------------------------------------------------------
// Dashboard / Home Page
//------------------------------------------------------------------------------
export const DASHBOARD_IMPORTANT_DISCLOSURES = `This information was prepared for your convenience. Only your Account Statement should be considered an official record of your holdings.

${ANCHORAGE_SERVICES_DISCLOSURE}

${ANCHORAGE_GUARANTEE_DISCLAIMER}

${COPYRIGHT}`;

const PRICE_INFORMATION = `Price information may be based on one market quote or a consolidated market quote. Any gain/loss calculation is an estimation of daily gains or losses and does not include adjustments that may be necessary for purposes of computing tax gains or losses or for reporting these gains or losses on tax returns. ${PRICE_PROVIDER}`;

export const PRICE_DISCLOSURE = `${ANCHORAGE_SERVICES_DISCLOSURE}

${ANCHORAGE_GUARANTEE_DISCLAIMER}

${PRICE_INFORMATION}`;

export const PRICE_DISCLOSURE_PORTO = `${PRICE_INFORMATION}`;

//------------------------------------------------------------------------------
// Statements Page
//-----------------------------------------------------------------------------

export const STATEMENTS_IMPORTANT_DISCLOSURES_SINGAPORE = `<strong>Important Information About Your Institutional Account</strong>

Your services are offered through Anchorage Digital Singapore Pte Ltd ("Anchorage Digital Singapore") and are limited to the scope of services set forth in your master custody services agreement or other agreement entered into between you and Anchorage Digital Singapore.

Anchorage Digital Singapore is an entity headquartered in Singapore and offers custody services out of Singapore. It is not registered with any government authority as a broker or dealer and is not authorized to and does not engage in the offer, sale, or trading of securities.

Price information may be based on one market quote or a consolidated market quote from reliable, reputable pricing sources, including CryptoCompare.com, and presented in USD for your convenience. Daily balances are calculated by applying closing prices, as provided by CryptoCompare.com at the close of each day. Any subsequent updates to historical daily closing prices by CryptoCompare.com will not be reflected in the daily balance calculation.  Such pricing sources are outside of Anchorage Digital Singapore’s direct control, and if prices are unavailable or unreliable due to low or inconsistent trading volumes for certain Digital Assets, Anchorage Digital Singapore may use fixed pricing as determined in Anchorage Digital Singapore’s reasonable sole discretion for such digital assets.

Please report any inaccuracies in your monthly statement to accountexecutive@anchorage.com. This statement is considered accurate if you do not report inaccuracies within 10 business days after receipt.

Digital assets held at Anchorage Digital Singapore are not subject to Federal Deposit Insurance Corporation (FDIC) or Singapore Deposit Insurance Corporation (SDIC) protections. Anchorage Digital Singapore is not a member of the Singapore Deposit Insurance (DI) Scheme and assets are not subject to the protections enjoyed by depositors with DI Scheme member institutions.

Anchorage Digital Singapore is not engaged in the offer, sale, or trading of securities, regulated commodity interests, or digital assets, and does not provide legal, tax, or investment advice. Holdings of cryptocurrencies and other digital assets are speculative and involve a substantial degree of risk, including the risk of complete loss. There can be no assurance that any cryptocurrency, token, coin, or other crypto asset will be viable, liquid, or solvent. No Anchorage Digital Singapore communication is intended to imply that any digital asset services are low-risk or risk-free. Digital assets held in custody are not guaranteed by Anchorage Digital Singapore.

${COPYRIGHT}`;

//------------------------------------------------------------------------------
// API
//------------------------------------------------------------------------------

export const API_V2_DISCLOSURE = `<strong>Please read the following disclosure carefully.</strong>

By generating this API key, you represent and warrant that you have the authority to do so.

By using this API key, you agree to defend, indemnify, and hold harmless Anchorage Digital from all claims, actions, suits, proceedings, or damages caused by your use or sharing of this API key or any actions taken or instructions submitted via the API key.

ANY HOLDER of the API key, whether authorized or unauthorized, will be able conduct the actions granted with the permission group associated with the key, including but not limited to obtaining read access, initiating transfers or withdrawals, creating holds, or executing trades using assets within your Anchorage Digital custody vault. It is your sole obligation to exercise care and discretion in the distribution of API keys. Anchorage Digital cannot monitor, control, or reverse any party's actions taken via an API key, and Anchorage Digital shall not be liable for following any instructions submitted via an API key. To revoke an API key, you may click "Revoke" on this dashboard.

If you choose to share this API key with an Anchorage Digital entity to initiate transfers or withdrawals, create holds, and/or execute trades using assets within your Anchorage Digital custody vault, you acknowledge and agree to: (a) any hold placed by the Anchorage Digital entity restricting withdrawal or transfer of assets in your Anchorage Digital custody vault; (b) transfers or withdrawals, and/or execution of trades using assets within your Anchorage Digital custody vault; (c) not revoke the API access key until the Anchorage entity releases such restriction; (d) not withdraw or transfer any restricted assets within your Anchorage Digital custody vault until the Anchorage Digital entity releases the restriction; and (e) in the event of inconsistency between instructions from you and the Anchorage Digital entity, the instructions from the Anchorage Digital entity will govern and your instructions may be disregarded.

Anchorage Digital makes no representations or warranties about the accuracy or completeness of the information accessed through the API key. Anchorage Digital is not responsible for any reliance on data generated through the API key. Only a client's Account Statement should be considered an official record of its holdings.

Anchorage Digital reserves the right to change, modify, add, or remove portions of the API at any time.`;

export const API_V2_ATLAS_DISCLOSURE = `[updated August 2024]

<strong>Please read the following disclosure carefully.</strong>

By generating this Shared API key for settlement services, you represent and warrant that you have the authority to do so.

If you choose to create this Shared API key for the sole use of settlement services and grant access to such Shared API key to an Anchorage Digital entity to  execute your directions following the quorum process agreed in the Master Custody Service Agreement. You acknowledge and agree that: (a) transfers or withdrawals, and/or holds (only applicable for two-way settlement services) using this Shared API key shall be using assets within your Anchorage Digital custody vault; and (b) once you grant access to such Shared API key to an Anchorage Digital entity for settlement services, such access cannot be revoked until the settlement services have been terminated.

Client’s Account Statement should be considered an official record of its holdings.
`;
