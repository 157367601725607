import Head from 'next/head';
import { useSearchParams } from 'next/navigation';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';
import usePortoTheme from '@anchorage/frontoffice/utils/usePortoTheme';
import LoginPage from 'components/LoginPage';
import replaceUrl from 'utils/navigation';
export default function Login() {
  const searchParams = useSearchParams();
  const isPorto = useIsPortoApp();
  usePortoTheme({
    active: isPorto
  });
  const pageTitle = isPorto ? 'Porto' : 'Anchorage Digital';
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="login.tsx">
        <title>{pageTitle}</title>
      </Head>
      <LoginPage searchParams={searchParams} navigate={replaceUrl} data-sentry-element="LoginPage" data-sentry-source-file="login.tsx" />
    </>;
}