// Number of times we'll generate a new session before timing out and asking
// the user to confirm to generate a session again. Current: 15 mins since the
// sessions are 1 min each
export const NUM_GEN_SESSION_CALLS_TIMEOUT = 15;

// Timeout for polling to check if the user is logged in
export const LOGIN_POLL_TIMEOUT_MS = 3000;

// Size of QR code
export const QR_CODE_SIZE = 270;
