import { debounce } from 'lodash';
import * as React from 'react';
type Props = {
  onHidePage: () => void;
  onResumePage: () => void;
  children: React.ReactNode;
};
type State = {
  visibilityState: string;
};
const VISIBILITY_CHANGE = 'visibilitychange';
class PageVisibilityWrapper extends React.Component<Props, State> {
  state = {
    visibilityState: ''
  };

  // Maintain a reference so we can cancel the event handler
  debouncedEventHandler = null;
  componentDidMount() {
    // Debouncing here is extremely important as chrome seems
    // to fire 3-5 visibilitychange events in a row when a user
    // hovers over the tab bar, but the last call is always right
    // @ts-ignore TODO nara - lodash typed as any; may need to
    // change logic & define our own Type
    this.debouncedEventHandler = debounce(this.onVisibilityChange, 200);
    document.addEventListener(VISIBILITY_CHANGE,
    // @ts-ignore TODO nara - lodash typed as any; may need to
    // change logic & define our own Type
    this.debouncedEventHandler, false);
  }
  componentWillUnmount() {
    // $ExpectError 'visibilitychange' is not in the flow defs
    // @ts-ignore TODO nara - lodash typed as any; may need to
    // change logic & define our own Type
    document.removeEventListener(VISIBILITY_CHANGE, this.debouncedEventHandler);
  }
  onVisibilityChange = () => {
    const {
      visibilityState: prevVisibilityState
    } = this.state;
    const {
      onHidePage,
      onResumePage
    } = this.props;

    // Initialize w/ initial visibility state
    if (!prevVisibilityState) {
      this.setState({
        visibilityState: document.visibilityState
      });
      return;
    }
    if (prevVisibilityState === document.visibilityState) {
      // Do nothing, this is a useless call to the event handler
      return;
    }

    // Now hiding via backgrounding
    if (document.hidden) {
      onHidePage();
    } else {
      onResumePage();
    }

    // Record this as the latest state
    this.setState({
      visibilityState: document.visibilityState
    });
  };
  render() {
    return this.props.children;
  }
}
export default PageVisibilityWrapper;