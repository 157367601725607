import css from './styles.module.css';
type Props = {
  className?: string;
};
const LoadingDots = ({
  className = ''
}: Props) => {
  return <span className={className} data-sentry-component="LoadingDots" data-sentry-source-file="index.tsx">
      <span className={css.dot}>.</span>
      <span className={css.dot}>.</span>
      <span className={css.dot}>.</span>
    </span>;
};
export default LoadingDots;