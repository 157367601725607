import { isEqual } from 'lodash';
import { ReadonlyURLSearchParams } from 'next/navigation';
import * as React from 'react';
import { reportError } from '@anchorage/common/dist/utils/errors';
import { DASHBOARD_PATH } from '@anchorage/frontoffice/utils/routes';
import { claimSession, generateSessionId } from '@anchorage/frontoffice/utils/userSessionApi';
import getRedirectPathName from 'utils/getRedirectPathName';
import Login from './Login';
import PageVisibilityWrapper from './PageVisibilityWrapper';
import { LOGIN_POLL_TIMEOUT_MS, NUM_GEN_SESSION_CALLS_TIMEOUT } from './constants';
type TimeoutID = ReturnType<typeof setTimeout>;
type Props = {
  navigate: (path: string) => void;
  searchParams: ReadonlyURLSearchParams;
};
type State = {
  isDevSession: boolean;
  isLoadingUserData: boolean;
  isServerError: boolean;
  sessionContents: {
    [key: string]: string;
  };
  generateSessionCalls: number;
};
class LoginPage extends React.Component<Props, State> {
  // context on "!" https://stackoverflow.com/questions/64874221/property-has-no-initializer-and-is-not-definitely-assigned-in-the-constructor
  claimSessionTimer!: TimeoutID;
  // Session refs used for testing
  getSessionRef!: void | Promise<void>;
  claimSessionRef!: Promise<void>;
  state = {
    isLoadingUserData: false,
    isDevSession: false,
    sessionContents: {
      SessionID: ''
    },
    isServerError: false,
    generateSessionCalls: 0
  };
  componentDidMount() {
    this.getSessionRef = this.getSessionId();
  }
  componentDidUpdate(_: Props, prevState: State) {
    if (!isEqual(prevState.sessionContents, this.state.sessionContents)) {
      // The session ID and QR code contents have been updated - we should
      // attempt to login now
      this.claimSessionRef = this.claimSession();
    }
  }
  componentWillUnmount() {
    // Clean up the timeout when the page transitions
    this.claimSessionTimer && clearTimeout(this.claimSessionTimer);
  }

  // When the page is hidden (tab is backgrounded), stop the requests
  // since they will be throttled on the browser and won't behave
  // as expected in some environments
  onHidePage = () => {
    this.claimSessionTimer && clearTimeout(this.claimSessionTimer);
  };

  // Start back up when the page resumes focus! If the session has since
  // expired, it will get a 400 back and appropriately call
  // getSessionID
  onResumePage = () => {
    this.claimSessionRef = this.claimSession();
  };
  handleError = (err: Error) => {
    reportError(err);
    this.setState(() => ({
      isServerError: true
    }));
  };
  goHome = async () => {
    const {
      navigate
    } = this.props;
    this.setState({
      isLoadingUserData: false
    });
    const redirectPathName = getRedirectPathName({
      path: this.props.searchParams.get('ref')
    });
    const newPage = redirectPathName.length ? redirectPathName : DASHBOARD_PATH;

    // Send them back to the page they were on, or the home page
    navigate(newPage);
  };
  getSessionId: () => void | Promise<void> = () => {
    if (this.state.generateSessionCalls >= NUM_GEN_SESSION_CALLS_TIMEOUT) {
      // Up the timeout since this is the last call
      return this.setState(state => ({
        generateSessionCalls: state.generateSessionCalls + 1
      }));
    }
    if (process.env.NEXT_PUBLIC_DEV_SESSION_ID) {
      return this.setState(state => ({
        isDevSession: true,
        sessionContents: {
          SessionID: process.env.NEXT_PUBLIC_DEV_SESSION_ID || ''
        },
        generateSessionCalls: state.generateSessionCalls + 1
      }));
    }
    return generateSessionId().then(res => {
      // Unexpected server error
      if (!res.ok) {
        // Log this error to help debug in Sentry
        // eslint-disable-next-line no-console
        console.error('Received an invalid response from the server', res);
        throw new Error(`Unable to generateSessionId with ${res.status} error ${res.statusText}`);
      }
      return res.text();
    }).then(text => {
      // Our server sometimes returns a 200 with an empty or null body.
      // res.json() cannot parse this and throws errors. This is meant to
      // handle and help debug this case
      if (!text || !text.length) {
        // eslint-disable-next-line no-console
        console.error('Received a response from the server with empty contents:', text);
        throw new Error('Unable to generateSessionId. Received an empty response');
      }
      return JSON.parse(text);
    }).then(json => {
      return this.setState(state => ({
        sessionContents: json,
        generateSessionCalls: state.generateSessionCalls + 1
      }));
    }).catch(this.handleError);
  };
  resumePolling = () => {
    // Clear the current count of generate session calls, and then get a new
    // session
    this.setState(() => ({
      generateSessionCalls: 0
    }), () => {
      this.getSessionRef = this.getSessionId();
    });
  };
  claimSession = () => {
    const {
      sessionContents
    } = this.state;
    if (!sessionContents) {
      return Promise.resolve();
    }
    if (sessionContents && !sessionContents.SessionID) {
      // This means that the backend's response has changed and no longer has a
      // SessionID key. This is critical so report it
      this.handleError(new Error('Unable to get SessionID from generateSessionId response. Cannot claim session.'));
      return Promise.resolve();
    }
    const sessionId = sessionContents.SessionID;
    return claimSession(sessionId).then(res => {
      if (res.status === 401) {
        // The session has not been claimed yet. This means the user must scan
        // the QR code still. Check again to see if they are logged in
        this.claimSessionTimer = setTimeout(() => {
          // Call claimSession again in LOGIN_POLL_TIMEOUT_MS ms
          // Save a ref to the promise for testing
          this.claimSessionRef = this.claimSession();
        }, LOGIN_POLL_TIMEOUT_MS);
        return Promise.resolve();
      }
      if (res.status === 400) {
        if (this.state.isDevSession) {
          throw new Error(`Invalid dev session token ${sessionId}`);
        }

        // This means the session has expired or been used; request a new one
        // and save a ref to the promise
        this.getSessionRef = this.getSessionId();
        return this.getSessionRef;
      }
      if (!res.ok) {
        // Unexpected server error
        // Log this error to help debug in Sentry
        // eslint-disable-next-line no-console
        console.error('Received an invalid response from the server', res);
        throw new Error(`Unable to claimSession for session ${sessionId} with ${res.status} error ${res.statusText}`);
      }

      // Successful response! User is logged in now.
      return res.json().then(() => {
        // Redirect to home.
        this.goHome();
      });
    }).catch(this.handleError);
  };
  render() {
    const {
      generateSessionCalls,
      sessionContents,
      isServerError,
      isLoadingUserData
    } = this.state;

    // The client has requested the max number of sessions in a row. Show the
    // screen that prompts them to request a new QR Code (session)
    const showTimeout = generateSessionCalls > NUM_GEN_SESSION_CALLS_TIMEOUT;
    return <PageVisibilityWrapper onHidePage={this.onHidePage} onResumePage={this.onResumePage} data-sentry-element="PageVisibilityWrapper" data-sentry-component="LoginPage" data-sentry-source-file="index.tsx">
        <Login isLoadingUserData={isLoadingUserData} isServerError={isServerError} onClickRefresh={this.resumePolling} qrCodeContents={sessionContents} showTimeout={showTimeout} data-sentry-element="Login" data-sentry-source-file="index.tsx" />
      </PageVisibilityWrapper>;
  }
}
export default LoginPage;