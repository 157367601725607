type Props = {
  path?: string | null;
};

const getRedirectPathName = ({ path }: Props): string => {
  if (!path) {
    return '';
  }

  let redirectURL;

  try {
    const url = new URL(path, window.location.origin);
    redirectURL = url.origin === window.location.origin ? url.pathname : '';
  } catch {
    redirectURL = '';
  }

  return redirectURL;
};

export default getRedirectPathName;
